// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseText;
    else return false;
}

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {

    var control, prod_id, from_page;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : '0';

        if (is_assoc_product !== '0') {
            from_page = 'assoc';
        } else {
            from_page = 'product';
        }

        if (is_regroup) {

            loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id);
        } else {

            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express_v2) {

                var in_wishlist = checkProductInWishlist();
                changeBtnAddWishlist(in_wishlist, prod_id);
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function(elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function() {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;

    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {

    if ($('.display_select_size').length) {
        $('.display_select_size').hide();
    }

    var control;
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {

        var id= form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        // En fonction de la couleur on regarde si le produit est disponible
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id, is_wishlist);
        // On regarde si le produit est dejà dans la wishlist
        checkProductInWishlist(id);

        if ($('#bloc_add_basket').is(":visible")) {
            if ($('#bloc_add_basket').hasClass('actif')) {
                $('#addToWishlistButton').hide();
            } else {
                $('#addToWishlistButton').show();
                $('#addToWishlistButton').removeClass('inactiv');
            }
        }
    }
    //On active le cta reservation en magasin
    $('#btn_resa_shop').removeAttr('disabled');
}

/**
 *
 * @param product_id : id du produit
 * @param regroup_ref_id : id du regroupement (si existant)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);

    // Loader
    btn.parent().addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id: '_' + product_id;
    var form = document.getElementById('prod_info' + elem);
    var color_id = 0;
    if (typeof form.elements['itm_color'] !== "undefined") {
        color_id = form.elements['itm_color'].value;
    }
    var size_id = form.elements['itm_size'].value;
    var txt_error = '';

    $("#ligne_pointure_error"+elem).remove();

    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    if ($('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) {

        $(this).parent().hide();
        $('#bloc_add_alert'+elem).show();
    } else {

        // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionenr la taille et la couleur
        if (color_id !== '' && size_id !== '') {
            waitingAddToBasket(event, form, '', regroup_ref_id);

            setTimeout(function() {

                btn.parent().removeClass('loading');
            }, 2000);
        } else {

            if (color_id === '' && size_id === '') {

                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size_and_a_color')+'</div>';
            } else if (color_id === '') {

                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_color')+'</div>';
            } else if (size_id === '') {

                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size')+'</div>';
            }

            setTimeout(function() {

                btn.parent().removeClass('loading');
            }, 600);
            $(txt_error).insertBefore($("#ligne_pointure"+elem));
        }
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();

    var plnb = form.getValue('produit_lot_nb');

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    var data = {
        idprod: form.getValue('produit_id'),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from === 'basket') {

        data.suff = form.getValue('produit_id');
    }

    if (plnb) {

        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            var blockAlert = $("#bloc_add_alert" + elem,$(form));
            var blocWrapperColorSize = $(".rollover_left",$(form));
            var blockColor = $("#ligne_couleur" + elem,$(form));
            var blockSize = $("#ligne_pointure" + elem,$(form));
            if (res.stock_tampon == 0) {

                $(".eclat_product_nouveaute_fiche_produit").show();

                if (!is_achat_express) {
                    $("#ligne_couleur").addClass("epuise");
                    $(".productDescrWrapper .date_livraison").addClass("epuise");
                    $("#bloc_add_basket").addClass("disabled");

                    $('#ligne_qte').append($('.restock_date'));
                    $('.taunt_text').css('display', 'none');
                } else {
                    $('.bloc_add_color', $(form)).hide();
                    var new_id_produit = id + itm_rr_id;
                    if ($('#tailleProd_' + new_id_produit, $(form)) != undefined && $('#tailleProd_' + new_id_produit, $(form)).length > 0) {
                        var input_size = $('#size_' + id + "_"+ $('#tailleProd_' + new_id_produit, $(form)).val() + itm_rr_id, $(form));

                        if (input_size != undefined && input_size.length > 0) {
                            $('#size_' + id + "_"+ $('#tailleProd_' + new_id_produit, $(form)).val() + itm_rr_id, $(form)).addClass('disabled');
                        }
                    }
                }

                blockAlert.show();
                blockAlert.addClass("actif");

                // Hide bloc colors and size
                blocWrapperColorSize.hide();

                $('#taunt_text').hide();

                if ($('#bloc_add_basket',$(form)) != undefined) {
                    $('#bloc_add_basket',$(form)).hide();
                }
            } else {
                var tauntText = $('#taunt_text');
                if (res.stock_tampon == 1) {
                    tauntText.show();
                    tauntText.html(Translator.translate('last_article'));
                } else if (res.stock_tampon > 0 && res.stock_tampon <= 3) {
                    tauntText.show();
                    tauntText.html(Translator.translate('limited_quantities'));
                } else {
                    $('#taunt_text').hide();
                }

                $(".eclat_product_nouveaute_fiche_produit").hide();
                if (!is_achat_express) {
                    $("#bloc_add_basket").removeClass("disabled");
                    $("#ligne_couleur").removeClass("epuise");
                    $(".productDescrWrapper .date_livraison").removeClass("epuise");

                    $('#bloc_add_basket').removeClass('inactif');
                    $('#btn_add_cart').removeAttr('disabled');
                } else {
                    $('.bloc_add_color', $(form)).show();
                }
                blockAlert.hide();
                blockAlert.removeClass("actif");

                // Show bloc colors and size
                blocWrapperColorSize.css('display', 'flex');

                if ($('#bloc_add_basket').length == 1) {
                    $('#bloc_add_basket').show();
                }
                pushIntoDatalayer(res.dataLayer_object);
                
            };
        }
    });
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }
            //dataLayer v2
        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];
            if (is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);

            }
            pushIntoDatalayer(dl_objet);
        }
        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');

        $("#cart_top").html(response_basket);

        var count_bask = $('#cart_top .cart').attr('data-cart');

        if (count_bask > 2 || count_bask > 1 && window.matchMedia("(max-height: 750px)").matches) {
            $("#cart_top .cart_product_listing").overlayScrollbars({});
        }

        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = $("#couleurProd" + i);
    var tailles = $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();

        if (tailles && tailles.val() != "none") idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(
            path_web +
                "ajax_upd_panier.php?prixu=" +
                prixU +
                "&idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&qte=" +
                qte +
                "&panierId=" +
                paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

function newshowmodal(products, type, root, id_produit, is_not_produit) {
    topCartAppear();
    window.setTimeout(function() {
        topCartDesappear();
    }, 5000);
}

var reassuSwiper;

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
 function topCartAppear() {
    var show_top_cart_parent = $('.my_basket');
    var count_bask = $('#cart_top .cart').attr('data-cart');

    if(count_bask > 2){
        $("#cart_top .cart_product_listing").overlayScrollbars({});
    }

    show_top_cart_parent.addClass("hover");
    $('#shad_menu').addClass("actif");

    /* Reassurance Rollover swiper */
    if ($(".rollover_reassurance_swiper").length) {
        reassuSwiper = new Swiper(".rollover_reassurance_swiper", {
            loop: true,
            centeredSlides: true,
            slidesPerView: 2.5,
            spaceBetween: 30,
            autoplay: {
                delay: 0,
                },
            speed: 5500,
            preventInteractionOnTransition: true,
            disableOnInteraction: false,
        });
    }
}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartDesappear() {
    var show_top_cart_parent = $('.my_basket');
    show_top_cart_parent.removeClass("hover");
    $('#shad_menu').removeClass("actif");
}

function paginate(page) {

    var form = document.getElementById('filter_sticky'),
        total_element = form.getValue('totalElems', parseFloat),
        nb_prod = form.getValue('nb_prod', parseFloat),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {

            updatePaginationGauge();

            // Initialise swipers after new products were added
            setTimeout(() => {
                initialiseMultiVisualSwipers();
            }, 1000);
        }
    });
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(page_bloc);
    } else {
        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, true);
    }
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function btnLoaderTrigger(evt) {
    "use strict";

    var element = $(this);
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        evt.preventDefault();

        loader.removeAttr("style").parent().addClass("loading");
        loader.removeClass("cache");
        loader.addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
            loader.addClass("cache");
            loader.removeClass("loading");
        }, 1200);

        if ($(this).attr("href")) {
            window.location.assign($(this).attr("href"));
        }
    }
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {

        setTimeout(function(){loader.removeAttr("style").parent().removeClass("loading")}, 200);
    }
}

/*********/
/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');

        subLogin.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

$(function() {

    setTimeout(function(){
        if($('#search_page').length) {
            placeFilterBar();
        }
    },3000);

    $(document).on("click", ".button", btnLoaderTrigger);

    /* BANNER GLOBAL-E */
    setTimeout(function(){
        var bannerGlobalE = $(".ge-free-shipping-container");
        var heightbannerGlobalE = bannerGlobalE.height();
        if (bannerGlobalE.length > 0) {
            $("#site_global_wrap").css("padding-top", heightbannerGlobalE);
        }
    },2500);


    /* Pre Header swiper */
    if ($(".promo_swiper .swiper-slide").length > 1) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            loop: true,
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.navpromo.promoNext',
                prevEl: '.navpromo.promoPrev',
            },
            speed: 1500,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });
    }

    /*Page sliders*/
    if($("#visual_scroller").length > 0) {
        var productThumbs = new Swiper('#visual_scroller', {
            slidesPerView: 'auto',
            spaceBetween: 10,
            direction: 'vertical',
            allowTouchMove: false,
        });
    }

    // PRODUCT SWIPER
    if($("#jqzoom .swiper-slide").length > 0) {
        //animation to avoid slide's width transition on pageload
        $('#jqzoom').animate({
            opacity: 1,
        },10);
        var main_productVisu = new Swiper('#jqzoom', {
            direction: 'vertical',
            loadOnTransitionStart: true,
            thumbs: {
                swiper: productThumbs
            },
            mousewheel: true,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-scrollbar',
                type: "progressbar",
            },
            preloadImages: false,
            loadPrevNext: true,
        });
    }

    /* RECHERCHE */
    $('#trigger_recherche').on("click touch", function() {

        if($('#site_head_wrap').hasClass('highlighted')) {
            closeRecherche();
        } else {
            $('.wrap_search_field').fadeIn().css("display", "flex");
            $('#site_head_wrap').addClass('highlighted');
            $('#shad_menu').addClass('actif');

            $('#shad_menu').on('click', function () {
                closeRecherche();
            });
        }
    });

    $('.wrap_search_field').mouseleave(function(){
        closeRecherche();
    })

    $('.close_search').on("click touch", function() {
        closeRecherche();
    });

    $("#search_home").on('input', function() {
        var value = $("#search_home").val();

        if(value.length >= 3){
            $('body').addClass('search_in');
            setTimeout(function(){
                placeFilterBar();
            },3000)
        } else {
            $('body').removeClass('search_in');
        }
    });

    // Gestion du rollover panier
    $(".my_basket").on("mouseenter", function() {
        topCartAppear();
    });

    $(".my_basket").on("mouseleave", function() {
        topCartDesappear();
    });
        
    // Gestion du rollover wishlist
    $(".block_top_item.wishlist:not('.wishlist_low_res')").on("mouseenter", function() {
        $(this).addClass('hover');
        $("#shad_menu").addClass("actif");

        /* Reassurance Rollover swiper */
        if ($(".rollover_reassurance_swiper").length) {
            reassuSwiper = new Swiper(".rollover_reassurance_swiper", {
                loop: true,
                centeredSlides: true,
                slidesPerView: 2.5,
                spaceBetween: 30,
                autoplay: {
                    delay: 0,
                    },
                speed: 5500,
                preventInteractionOnTransition: true,
                disableOnInteraction: false,
            });
        }
    });

    // Gestion du shad pour le menu
    $(".my_account.is_logged").on("mouseenter", function() {
        $(this).addClass('hover');
        $("#shad_menu").addClass("actif");
    });

    $(".my_account.is_logged").on("mouseleave", function() {
        $(this).removeClass('hover');
        $("#shad_menu").removeClass("actif");
    });
    
    $(".block_top_item.wishlist:not('.wishlist_low_res')").on("mouseleave", function() {
        $(this).removeClass('hover');
        $("#shad_menu").removeClass("actif");
    });

    /***** BURGER MENU *****/
    $('.burger_menu_container').click(function() {
        $('.burger_menu').toggleClass('is_clicked');
        $('.main_menu_wrapper').toggleClass('is_open');
        $('#shad_menu').toggleClass('actif');
        $('#header_mention_id, .header').toggleClass('nav_burger_open');
    });
    
    var menu_timeout = setTimeout(null);

    $(".menu_itm_hover").on("mouseenter", function(evt) {
        // var mainmenu = $(".main_menu");
        var submenu = $(".submenu_wrapper", this);
        evt.preventDefault();
        clearTimeout(menu_timeout);
        
        var linkToClick = $(this).find('.has_submenu a');

        //Si on est en résolution supérieure à 1280, on gère l'effet de hover sur les items du menu
        if (window.matchMedia("(min-width: 1281px)").matches) {

            submenu.addClass('menu_visible');
            
        //Sinon, on gère la navigation dans le menu burger au clic sur les items
        } else {
            
            var retourToIndex = $(this).find('.retour_low_res');

            linkToClick.on('click', function() {
                $(this).closest('.main_menu_itm').addClass('next_menu');
            });
            retourToIndex.on('click', function() {
                $(this).closest('.main_menu_itm').removeClass('next_menu');
            });
        }
        
        //On empêche de rediriger vers la boutique et on gère le glissement de la boutique vers les catégories associées si 1280px
        linkToClick.click(function(event){
            if (window.matchMedia("(min-width: 1281px)").matches) {
                return true;
            }
            else {
                // Exception menu -1280px pour le menu nouveauté
                if ((linkToClick).attr('href').includes('nouveautes')) {
                    return true;
                } 
                else {
                    event.preventDefault();
                }
            }
        });
    });
    
    // Exception menu +1280px pour le menu collection
    $('#menu_collections a').attr('href', 'javascript:void(0)');

    $(".menu_itm_hover").on("mouseleave", function() {
        if (window.matchMedia("(min-width: 1281px)").matches) {
            var submenu = $(".submenu_wrapper", this);
            submenu.removeClass('menu_visible');
        }
    });

    if ($('#cart_top #cart_list .product-link').length > 2 || window.matchMedia("(max-height: 750px)").matches && $('#cart_top #cart_list .product-link').length > 1) {
        $("#cart_top #cart_list").overlayScrollbars({});
    }

    if($('#wishlist_top #cart_list .product-link').length > 2 || window.matchMedia("(max-height: 750px)").matches && $('#cart_top #cart_list .product-link').length > 1) {
        $("#wishlist_top #cart_list").overlayScrollbars({});
    }

    $('.switch_lang').on({
        mouseenter: function() {
            $(this).find('.lang_itm:not(.active)').addClass('show');
        },
        mouseleave: function() {
            $(this).find('.lang_itm:not(.active)').removeClass('show');
        },
    });

    /* FICHE PRODUIT - REASSURANCE SWIPER */
    if ($(".product_page .rollover_reassurance_swiper").length) {
        reassuSwiper = new Swiper(".rollover_reassurance_swiper", {
            loop: true,
            slidesPerView: 2,
            centeredSlides: true,
            speed: 5000,
            spaceBetween: 30,
            autoplay: {
                delay: 1,
            },
            preventInteractionOnTransition: true,
            disableOnInteraction: false
        });
    }

    if ($('.primary_navbar').length) {
        $('.navbar_tab').click(function () {
            var tab = $(this).data('tab');
            // Identify navbar to handle multiple primary_navbar occurences
            var navbar = $(this).parent().attr('data-navbar');

            /* change nav active on this elem **/
            $(this).parent().find($('.navbar_tab')).removeClass('active');
            $(this).addClass("active");

            /* If nav tabs have specific content to show **/
            if($('.tab_content').length) {
                $(".tab_content[data-navbar='" + navbar +"']").removeClass('actif')
                $(".tab_content[data-tab='" + tab + "'][data-navbar='" + navbar +"']").addClass("actif");
            }
        });
    }

    //PLACE SEE MORE FILTER BAR
    placeFilterBar();

});

function closeRecherche(){
    if($('.wrap_search_field').is(":visible")) {
        $('.wrap_search_field').fadeOut();
        $('#site_head_wrap').removeClass('highlighted');
        $('#shad_menu').removeClass('actif');
    }
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: {lang: locale, url: uri},
        success: function(data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }
                location.href = data + force;
            }
        },
    });
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
            default:
                if (elmt.attr("href")) {
                    window.location.assign(elmt.attr("href"));
                }

                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

function ajaxDeconnect(path_relative_root) {
    var provenance = window.location.pathname,
        cpt_client = new RegExp(
            /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
        ),
        response;

    // Get the current page's name
    provenance = provenance.substr(
        provenance.lastIndexOf("/"),
        provenance.lastIndexOf(".")
    );

    // This actually disconnects the client
    response = ajax_file(path_relative_root + "ajax_deconnect.php");

    // If there was an error
    if (!response) alert("Erreur lors de la déconnexion.");
    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href =
            path_relative_root + create_link("connexion_login");
    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

function process_type_preco() {

    var val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + "ajax_choose_preco.php",
        success: function(response) {

            if (response.status == "success") {

                if ($("input[name=preco_choice]").length == 0) {

                    closeMultiShad("lightbox_preco");
                }

                var index_conveyor = typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function(i, item) {

                    array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                    array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                    array_transporteurs_datas[i]["fdp"]["format"] = item["format"];
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .pricetag"
                    ).html(item["format"]);
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .price_tag"
                    ).html(item["format"]);
                });

                if (val == "several") {

                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr( "checked", "checked");
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {

                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr("checked","checked");
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}

function openMultiShad(id, slide) {

    $("#" + id).addClass('actif');
    $("#shad").addClass('actif');

    $("#shad").on("click", function () {

        closeMultiShad(id);
    });

    if(id === "zoomBox") {

        setTimeout(function() {
            scrollToAnchor(slide);
        },50)

        $('body').css('overflow', 'hidden');

        $('.productLightboxVisu .img_large').on('click', function () {
            $('body').css('overflow', 'initial');
            closeMultiShad('zoomBox');
        });
    }

    //GUIDE DES TAILLES
    if (id === "sizeguideBox") {
        $("#wrap_itable").overlayScrollbars({});
    }

}

function closeMultiShad(id) {

    $('#shade').off('click');
    $("#" + id).removeClass('actif');

    if ($("#shad").hasClass('actif')) {
        $("#shad").removeClass('actif');
    }

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#shade').is(":visible")) {
            $('#shade').fadeTo("slow", 0, function () {

                $('#shade').css('display', 'none');
            });
        }

        $(this).dequeue();
    });

    // Précommade
    if (id == 'lightbox_preco') {
        $('#cart_total').css('visibility', 'visible');
    }
}

function zoomImg(slide) {

    openMultiShad('zoomBox', slide);

}

function scrollToAnchor(aid){

    var myElement = document.getElementById("img" + aid + "");
    var topPos = myElement.offsetTop;

    //On vérifie si on est pas déjà sur l'index correspondant à l'image
    if (!$('#index_img' + aid).hasClass('active')) {

        //Si pas le cas, on ajoute la classe à cet index et on la supprime de tous les autres
        $('.index_zoom a').each(function() {
            $(this).removeClass('active');
        })

        $('#index_img' + aid).addClass('active');

        //Puis on scroll
        $('#zoomBox').animate({
            scrollTop: topPos
        }, 'slow');

    }
}

var imgsZoom = $('.productBigPictures .img_zoom');

//Gestion de l'index actif dès que l'utilisateur scroll
//l'event mousewheel permet de ne pas s'appliquer lors du scrolltop de la fonction précédente
$('#zoomBox').bind('wheel',function(){
    
    imgsZoom.each(function() {

        var offset = $(this).offset();
        var heighOfElem = $(this).height();
        var idOfElem = $(this).attr('id');

        //En fonction de la position de l'image, on défini l'index de celle-ci comme actif ou non
        if (offset.top < 150 && offset.top >= -heighOfElem) {
            $(this).addClass('active');
            $('.index_zoom a').each(function() {
                $(this).removeClass('active');
            });
            $("#index_"+idOfElem+"").addClass('active');
        } else {
            $(this).removeClass('active');
        }

    })
});


/**
 * Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
 */
 function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr("id"),
        mId = id.substring(id.indexOf("_") + 1),
        messageContainer = $(".message_account_container");

    if (messageContainer.hasClass("appear")) {
        messageContainer.removeClass("appear");
    } else {
        messageContainer.addClass("appear");
    }

    if (!elem.hasClass("actif")) {
        $(".message_detail").slideUp();
        $(".btn_ouvrir_message")
            .not(elem)
            .removeClass("actif");
        $(".btn_ouvrir_message")
            .not(elem)
            .text(msg_open);

        if ($("#msg_txt_" + mId).html() == "") {
            $.ajax({
                type: "post",
                data: {mId: mId, type: 1},
                url: path_relative_root + create_link("message_detail"),
                success: function(response) {
                    $("#msg_txt_" + mId).html(response);
                    $("#msg_" + mId).slideDown();
                },
            });
        } else $("#msg_" + mId).slideDown();
        elem.addClass("actif");
        elem.text(msg_close);
    } else {
        $(".message_detail").slideUp();
        elem.removeClass("actif");
        elem.text(msg_open);
    }
}

function show_message(mId, hide) {
    if (hide == 0) {
        var ajax_content = ajax_file("ajax_show_messages.php?mId=" + mId);
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = "";
            document.getElementById("message_" + mId).style.cursor = "default";
        }
    } else {
        var ajax_content = ajax_file(
            "ajax_show_messages.php?mId=" + mId + "&hide=1"
        );
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick =
                "show_message(" + mId + ",0);";

            document.getElementById("message_" + mId).style.cursor = "pointer";
        }
    }
}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {
    $.ajax({
        type: "post",
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte,
        },
        url: path_relative_root + "ajax_removeBasketProd.php",
        success: function(res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            $("#cart_top").html(res.datas.html);
            $('#show_top_cart').addClass('appear');

            var count_bask = $('#cart_top .cart').attr('data-cart')

            if(count_bask > 2){
                $("#cart_top .cart_product_listing").overlayScrollbars({});
            }
        },
    });
}

/*
 * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                if (window.dataLayer) {
                    window.dataLayer.push({
                        "event": "stockAlert",
                    });
                }
            },
            fail: function() {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Show bloc colors and size
    $('#ligne_pointure_' + elem).show();
    $('#ligne_couleur_' + elem).show();
    //$("#prod_info_" + elem + " .bloc_add_color").show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('.wishlistBtn').show();
    $('.form_submit.bloc_add_color').show();

    //Reset input selection
    $('#size_list_' + elem + ' input').prop('checked', false);
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {

    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;

    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v1 = $('.express_response').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : (is_wishlist || is_achat_express_v1) ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1
    };
    
    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + 'ajax_get_product_regroup_ref.php',
        data : data,
        success : function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;

                if (data.type_page == 'product') {

                    var $html_main = $(stripCombo(datas.html_main));

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);

                    } else if (is_wishlist || is_achat_express_v1) {
                       
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                        if (!$(".w-alertestock-form").length) {
                            new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                        }

                    } else {
            
                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        setTimeout(function(){
                            placeProductColors();
                        }, 500);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        toggleCaract();
                        /*Page sliders*/
                        if($("#visual_scroller").length > 0) {
                            var productThumbs = new Swiper('#visual_scroller', {
                                slidesPerView: 'auto',
                                spaceBetween: 10,
                                direction: 'vertical',
                            });
                        }

                        // PRODUCT SWIPER
                        if($("#jqzoom .swiper-slide").length > 0) {
                            //animation to avoid slide's width transition on pageload
                            $('#jqzoom').animate({
                                opacity: 1,
                            },10);
                            var main_productVisu = new Swiper('#jqzoom', {
                                direction: 'vertical',
                                loadOnTransitionStart: true,
                                thumbs: {
                                    swiper: productThumbs
                                },
                                mousewheel: true,
                                slidesPerView: 1,
                                pagination: {
                                    el: '.swiper-scrollbar',
                                    type: "progressbar",
                                },
                                preloadImages: false,
                                loadPrevNext: true,
                            });
                        }

                        //REASSURANCE SWIPER
                        var reinsuranceMarquee = new Swiper('.reinsuranceMarquee', {
                            loop: true,
                            slidesPerView: 'auto',
                            spaceBetween: 24, 
                            speed: 5000,
                            autoplay: {
                                delay: 1,
                            },
                        });
                    }

                } else if (data.type_page == 'assoc') {
                
                    if (data.is_achat_express_v2) {
                   
                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

            } else {
                $( '#btn_error' ).fadeIn();
            }
        },
        complete : function () {

            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {

                checkSubmitState();
                eventButton();

                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();

            }, 500);

            heads.each(function(){
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist);

            $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);

            if (!data.is_achat_express_v2) {
                checkProductInWishlist(data.produit_id_new);
            }

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            if ($('body.category').length || $('#search_page').length) {
                // Initialise visual swipers after color change
                initialiseMultiVisualSwipers();
            }
        }
    });
}

var bloc_prod_xhr;

//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {};

    // Add misc values to output object
    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', fromPagination);

    if (fromScrollTop || fromScrollBottom) {
        $('#initial_page').val(page + 1);
        seeAllBtnClick("remove");
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_products.php',
        type: 'get',
        data: output,
        success: function (res) {
            var
                old_elm = document.getElementById('list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');
            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            // This block of code updates the SEO-related link elements in the HTML document.
            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if (content !== '') {

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".autoLoad").fadeTo("fast", 0);
                } else {
                    old_elm.innerHTML = "";
                }

                document.getElementById('totalElems').value = itm_length;
                paginate(page + 1);
                old_elm.insertAdjacentHTML('beforeend', content);

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }
            }
        },
        complete: function () {
            // force trigger on input hidden to fire the change() event
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            if (fromScrollTop || fromScrollBottom) {
                seeAllBtnClick("add");
            }

            if (!fromScrollBottom && !fromScrollTop) {
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 200);
                }, 250);
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
            }

            if (fromScrollTop || fromScrollBottom){
                $('#is_loading').val('0');
            }

            if(fromScrollBottom){
                $("#initial_page").val(page +1);
            }

            updatePaginationGauge();
        }
    });
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("onglet_" + i + "_mid")) {
        if (type == i) {
            $("#onglet_" + i + "_left").attr("class", "left-on");
            $("#onglet_" + i + "_mid").attr("class", "mid-on");
            $("#onglet_" + i + "_right").attr("class", "right-on");
        } else {
            $("#onglet_" + i + "_left").attr("class", "left-off");
            $("#onglet_" + i + "_mid").attr("class", "mid-off");
            $("#onglet_" + i + "_right").attr("class", "right-off");
        }
        i++;
    }

    var container = document.getElementById(elementID);

    //clearTimeout(time_out);
    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
            return true;
        }
        else{
            container.innerHTML = '';
            return false;
        }
    }
    else
        return false;
}

var global_id_transporteur = '';
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    var divId = 'popup_numero_retour';
    divId += '_'+(multiTransp?idOrderTransp:idOrder);
    var formId = '#return_products_form';
    formId += '_'+(multiTransp?idOrderTransp:idOrder);

    $('#'+divId).addClass('actif');
    $("#return_products_form").lionbars({});
    $("#shad").addClass('actif');
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp)
{
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour+'&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    }

    $.ajax({
        url : path_relative_root + 'ajax_create_etiquette.php',
        type : 'post',
        data : data,
        success : function (res) {
            if (res.substr(0,5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, multiTransp) {

    var has_errors = false;
    $(".return_quantity").each(function() {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0  && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form_' + idOrder).serialize();
        data += '&idOrder='+idOrder+'&multiTransp='+multiTransp;

        $.ajax({
            url : path_relative_root + 'ajax_create_etiquette.php',
            type : 'post',
            data : data,
            success : function (res) {
                location.reload();
            }
        });
    }
}


/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
 function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
                if( is_disabled )  {
                    size_box.addClass('disabled');
                }
                else {
                    size_box.removeClass('disabled');
                }
            }
        }
    }
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                    parentPath +
                    'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
                "ajax_liste_qte.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                "&produit_lot_nb=" +
                $("#produit_lot_nb").val() +
                "&suff=" +
                (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}
/**
 * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
 */
 function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {

            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {

            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (iserrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {

        $('.bloc_add_alert_form').slideDown('fast');
    }
}


/**
 * Pour la gestion de la page faq.php
 * @author Aurélien Renault
 * @since 2019/03/08
 */

/**
 * Mise en pace des event
 */
 function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        $('#theme_page .theme').each(function(){
            $(this).removeClass('active');
        });

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];
            
            // On affiche uniquement celles qui correspondent à la recherche
            $.each( array_questions, function( i, val ) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());	
            
                if ( match ) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }
    
        } else {		
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });
    
    var click_in_process = false;

    $('.search_results').mousedown(function() {
        click_in_process = true;
    });

    $('.search_results').mouseup(function() {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function() {
        if(!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function() {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display','none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function() {
        var id = $(this).data('id');
        showTabFaq(id, 1);
     
        if(!$(this).hasClass('active')) {
            $('#theme_page .theme').each(function(){
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    
    });
}

function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function() {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");
    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        //active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        //show questions
        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function() {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function(idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function() {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function() {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function() {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });
    }
}

function researchFaq(node_id, level, question_id) { 

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({scrollTop: $(".faq_question_" + question_id + "").offset().top - 100}, 1000);

    $('#theme_page .theme[data-id='+node_id+']').addClass('active');
}

/**
 * PLACE COLOR INPUTS - PRODUCT PAGE
*/
function placeProductColors() {
    if ($('.product_info .item_price_wrapper').length) {
        var colorContainer = $('#product_selects #ligne_couleur');
        var priceWrapperWidth = $('.product_info .item_price_wrapper').width();
        var distanceToSet = priceWrapperWidth + 30;
        colorContainer.css({'left': distanceToSet});
        colorContainer.animate({
            opacity: 1
        },50);
    }
}

let delay; 
if (getCookie("cookie_basic") == '' && getCookie("GlobalECartId") != '') {
    delay = 3000;
}else{
    delay = 1000;
}

setTimeout(function(){
    placeProductColors();
}, delay);

/**
 * Toggle caract - Product page - Adding class while toggle to prevent multi click animations
 */
function toggleCaract(){
    $('.carac_container strong').click(function() {
        if($(this).hasClass('toggling')){return;}

        if($(this).hasClass('toggled')) {
            $(this).removeClass('toggled')
        } else {
            $(this).addClass('toggling toggled');
        }
        
        $(this).next('.carac_container_detail').slideToggle(500, function() {
            $(this).siblings('strong').removeClass('toggling');
        });
    });
}
toggleCaract();


//FILTRE ~ Nombre actif
$(document).on("change",".selected_options", function () {
    var trigger_wrapper = $(this).parent().find('.nbr_actif');
    var countActif = 0;

    $(this).find($('input[type="checkbox"]:checked')).each(function() {
        countActif++
    });

    $(this).find($('input[type="radio"]:checked')).each(function() {
        countActif++
    });

    if(countActif > 0){
        trigger_wrapper.text('('+ countActif +')');
    } else {
        trigger_wrapper.text('');
    }
    placeFilterBar();
});

$(document).on("click",".selected_options a", function () {
    var trigger_wrapper = $(this).parent().parent().find('.nbr_actif');
    var countActif = 0;

    if($(this).hasClass('actif')){
        countActif++
    }

    if(countActif > 0){
        trigger_wrapper.text('('+ countActif +')');
    } else {
        trigger_wrapper.text('');
    }
    placeFilterBar();
});

//PLACE SEE MORE FILTER BAR - CATEGORY - SEARCH
function placeFilterBar() {
    if($('.show_more_filters_contain:not(.toggled)').length) {

        if($('.filter_wrapper').length > 2) {

            setTimeout(function() {
                //On récupère la largeur additionnée des trois premiers élements + du dernier de la barre des filtres
                var calcLeft = $('.filters_container .txt_bandeau').outerWidth(true) + $('.filters_container .filter_wrapper:eq(0)').outerWidth(true) + $('.filters_container .filter_wrapper:eq(1)').outerWidth(true);

                var calculRight = $('#filter_trier').innerWidth();

                //On définit la largeur et la position de la barre qui masquera les filtres supplémentaires
                $('.show_more_filters_contain').css({
                    width: $(this).width() - calcLeft - calculRight - 30,
                    right: calculRight,
                });
                
                //Apparition du bouton "Plus" pour faire apparaitre les autres filtres
                $('.show_more_filters_contain .show_more_filters').animate({opacity: 1},500);

            },250);

        }
         else {
            //Si pas plus de deux filtres possibles, on enlève le cta "PLUS"
            $('.show_more_filters_contain').hide();
        }
    }
}

//REPLACE FILTER BAR ON WINDOW RESIZE
$(window).on('resize', function () {
    placeFilterBar();
});

/**
 * Toggle filters - Category - Search
 */
 function toggleFilters(obj) {
    $(obj).parent('.show_more_filters_contain').animate({
        width: 0
    },500)
    $(obj).parent('.show_more_filters_contain').addClass('toggled');
    $(obj).text('');
}

/***** FUNCTION PUSH INTO DATALAYER  *****/

function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

function updatePaginationGauge() {
    /**
* UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
*/
    var productLoaded = $(".item.wrapperProdVertical").length;
    var total_element = document.getElementById('totalElems').value;
    var percentLoaded = (productLoaded * 100 / total_element);

    $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, total_element));

    $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

    if(productLoaded >= total_element) {
        window.setTimeout(function() {
            $('.wrap_flitre_pager .loader_scroll').removeClass('loading');
        }, 2000);
    }
}